import React from 'react';
import * as Icon from 'react-feather';
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import { useState } from 'react';

const ComingSoon = () => {
	const [days, setDays] = React.useState('');
    const [hours, setHours] = React.useState('');
    const [minutes, setMinutes] = React.useState('');
    const [seconds, setSeconds] = React.useState('');
    const [isSubmited, setSubmitted] = React.useState(false);

    React.useEffect(() => {
        const interval = setInterval(() => { 
            commingSoonTime();
        }, 1000);
        return () => clearInterval(interval);
    }, [])

    const commingSoonTime = () => {
        let endTime = new Date("March 7, 2022 00:00:00 GMT+3");
        let endTimeParse = (Date.parse(endTime)) / 1000;
        let now = new Date();
        let nowParse = (Date.parse(now) / 1000);
        let timeLeft = endTimeParse - nowParse;
        let days = Math.floor(timeLeft / 86400); 
        let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
        let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
        let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
        if (hours < "10") { hours = "0" + hours; }
        if (minutes < "10") { minutes = "0" + minutes; }
        if (seconds < "10") { seconds = "0" + seconds; }
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
    }

    
  const { register, handleSubmit } = useForm();
  const onSubmit = (data, e) => {
    e.preventDefault()
    fetch(`https://api.codeofduty.se/v2/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json"},
        body:JSON.stringify(data)
      })
        .then((response) => {
            setSubmitted(true)
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
  };
  const onError = (errors, e) => console.log(errors, e);


    return (
        <div className="coming-soon-area">
			<div className="d-table">
				<div className="d-table-cell">
					<div className="container">
						<div className="coming-soon-content">
                            <h1>Under Construction</h1>
                            <p>Our website is currently undergoing scheduled maintenance.</p>
                            { isSubmited ? null:
                            <form onSubmit={handleSubmit(onSubmit, onError)}
                                name="contact-form" method="POST" >
                                
                                <input type="hidden" name="form-name" value="coming-soon-form" {...register("form-name")}/>
                                
                                <input type="hidden" name="Phone" value="not-avilable" {...register("Phone")}/>
                                <input type="hidden" name="formId" value="coming-soon-form" {...register("form-id")}/>  
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="email" className="email-input" placeholder="Enter email address" name="email" required {...register("email")}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <button type="submit" className="submit-btn">
                                                Notify Me
                                            </button>
                                        </div>
                                    </div>
                                </div>
							</form>}

                            { isSubmited ? 
                            <div class="timer">
                                <h3>Thank you</h3>
                            </div>
                            : null}
                           
							<div id="timer">
                            
                                <div id="days">
                                    {days} <span>Days</span>
                                </div>
                                <div id="hours">
                                    {hours} <span>Hours</span>
                                </div>
                                <div id="minutes">
                                    {minutes} <span>Minutes</span>
                                </div>
                                <div id="seconds">
                                    {seconds} <span>Seconds</span>
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</div>

            {/* <ul className="social-list">
                <li className="list-heading">Follow us for update:</li>
                <li>
                    <a href="https://www.facebook.com/" className="facebook" target="_blank" rel="noreferrer">
                        <Icon.Facebook />
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/" className="twitter" target="_blank" rel="noreferrer">
                        <Icon.Twitter />
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/" className="linkedin" target="_blank" rel="noreferrer">
                        <Icon.Linkedin />
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/" className="instagram" target="_blank" rel="noreferrer">
                        <Icon.Instagram />
                    </a>
                </li>
            </ul> */}
		</div>
    )
}

export default ComingSoon;